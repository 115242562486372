<template>
  <v-container grid-list-xs>
    <v-layout row wrap mt-5>
      <v-flex xs12 sm6>
        <v-slider
          label="Daily Orders"
          v-model="daily_orders"
          color="red"
          track-color="primary"
          thumb-color="primary"
          thumb-label="always"
          tick-size="2"
          max="200"
        ></v-slider>
      </v-flex>
      <v-flex xs12 sm5 offset-sm1>
        <v-slider
          label="Average Price $"
          v-model="average_price"
          color="red"
          track-color="primary"
          thumb-color="primary"
          show-current
          step="5"
          thumb-label="always"
          ticks
          max="200"
        ></v-slider>
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex xs12 sm6>
        <v-slider
          label="Average Commission %"
          v-model="average_commission"
          color="red"
          track-color="primary"
          thumb-color="primary"
          thumb-label="always"
          step="5"
          ticks
          tick-size="2"
          max="50"
        ></v-slider>
      </v-flex>
      <v-flex xs12 sm5 offset-sm1>
        <v-slider
          label="Average Tips %"
          v-model="average_tips"
          color="red"
          track-color="primary"
          thumb-color="primary"
          show-current
          step="5"
          thumb-label="always"
          ticks
          max="50"
        ></v-slider>
      </v-flex>
    </v-layout>
    <v-layout py-5 column align-center justify-center> </v-layout>
    <v-layout py-5 column align-center justify-center>
      <v-flex xs12>
        <h1>Increase Monthly Net Margin by {{ total_saving | currency }}</h1>
      </v-flex>
    </v-layout>
    <v-layout column align-center justify-center>
      <v-btn
        class="takein_logo mt-5"
        dark
        rounded
        :href="domain + '/signup/chef'"
      >
        {{ $t('Chef.chef_signup_sub') }}
      </v-btn>
    </v-layout>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      daily_orders: 30,
      average_price: 50,
      average_commission: 20,
      average_tips: 15
    }
  },
  computed: {
    domain() {
      return `https://${process.env.VUE_APP_DOMAIN}`
    },
    total_saving() {
      return this.total_commission + this.total_tips
    },
    total_commission() {
      return (this.total_month * this.average_commission) / 100
    },
    total_tips() {
      return (this.total_month * this.average_tips) / 100
    },
    total_month() {
      return this.daily_orders * this.average_price * 24
    }
  }
}
</script>
